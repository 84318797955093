
import { Options, Vue } from 'vue-class-component';
import { HeadquartersClient, WorkShiftDayClient } from '@/services/Services';
import { showNotification } from '@/utils';
import UpdateWorkShiftTypeModal from './modals/updateWorkShiftTypeModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class WeekSchedule extends Vue {

    startDate: string = null;
    endDate: string = null;
    headquarterId: string = "";

    headquartersList: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    period: Date[] = [];
    ris: OM.WorkShiftDayVm[] = [];

    created() {
        HeadquartersClient.getAllAsSelect()
        .then(x => {
            this.headquartersList = x;

            if(this.headquartersList.length > 0) {
                this.headquarterId = this.headquartersList[0].identifier;
                this.startDate = new Date().toISOString();
                
                var today = new Date();
                var dayOfWeek = today.getDay();
                var daysUntilNextSunday = (7 - dayOfWeek) % 7;

                if (daysUntilNextSunday === 0) {
                    daysUntilNextSunday = 7;
                }

                var nextSunday = new Date(today);
                nextSunday.setDate(today.getDate() + daysUntilNextSunday);

                this.endDate = nextSunday.toISOString();
                this.updateTable();
            }
        })
    }

    get updateButtonDisabled() {
        return !this.startDate || !this.endDate || !this.headquarterId;
    }

    updateTable() {
        WorkShiftDayClient.getAllWorkShiftDaysInRangeByHeadquarters(this.startDate, this.endDate, this.headquarterId)
        .then(x => {
            this.getDates();
            this.ris = x;

            setTimeout(() => {
                this.loaded = true;
            }, 300);
        })
    }

    getDates() {
        this.period = [];
        var endDate = new Date(this.endDate)
        var currentDate = new Date(this.startDate);

        while (currentDate <= endDate) {
            this.period.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    get groupedRis() {
        return this.ris.reduce((accumulator, currentValue) => {
            if (!accumulator[currentValue.employeeId]) {
                accumulator[currentValue.employeeId] = [];
            }

            accumulator[currentValue.employeeId].push(currentValue);
            return accumulator;
        }, {});
    }

    getWorkShiftDay(user: any, date: Date) {
        return (user.filter(x => new Date(x.day).getDate() == date.getDate() && new Date(x.day).getMonth() == date.getMonth()))[0];
    }
    getSumWorkingDays(employeeId: string) {
        return this.ris
            .filter( x => x.employeeId == employeeId)
            .filter( x => x.workShiftType.score > 0)
            .reduce((partialSum, current)=> {
                return partialSum + 1;
            }, 0)
    }

    changeWorkShiftType(user: any, date: Date) {
        var workShiftDay = this.getWorkShiftDay(user, date);
        if(!workShiftDay)
            return;

        this.$opModal.show(UpdateWorkShiftTypeModal, {
            employeeName: workShiftDay.employeeName,
            headquarterIdentifier: this.headquarterId,
            workshiftDayIdentifier: workShiftDay.identifier,
            workShiftTypeId: workShiftDay.workShiftType.identifier,
            vanIdentifier: workShiftDay.vanId,
            vanSigla: workShiftDay.vanSigla,
            date: date,
            callback: (newWorkShiftTypeId: string, newVanIdentifier: string) => {
                WorkShiftDayClient.updateWorkShiftType(workShiftDay.identifier, newWorkShiftTypeId)
                .then(x => {
                    WorkShiftDayClient.updateVan(workShiftDay.identifier, newVanIdentifier)
                    .then(y => {
                        this.$opModal.closeLast();
                        showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));
    
                        this.updateTable();
                    })
                })
            }
        })
    }

}

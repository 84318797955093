
import { WorkShiftTypeClient, VanClient, WorkShiftDayClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class UpdateWorkShiftTypeModal extends Vue {

    @Prop() employeeName: string;
    @Prop() headquarterIdentifier: string;
    @Prop() workshiftDayIdentifier: string;
    @Prop() workShiftTypeId: string;
    @Prop() vanIdentifier: string;
    @Prop() vanSigla: string;
    @Prop() date: Date;
    @Prop() callback: any;

    newWorkShiftTypeId: string = "";
    workShiftTypesSelectList: OM.TextIdentifier[] = [];
    workShiftTypesList: OM.WorkShiftType[] = [];

    newVanIdentifier: string = "";
    vanList: OM.TextIdentifier[] = [];

    created() {
        this.newWorkShiftTypeId = this.workShiftTypeId;
        this.newVanIdentifier = this.vanIdentifier;

        Promise.all([
            WorkShiftTypeClient.getAll(),
            VanClient.getAllVansByHeadquarter(this.headquarterIdentifier),
            WorkShiftDayClient.getVansAvailabled(this.date.toISOString())
        ])
        .then(xs => {
            this.workShiftTypesList = xs[0];
            this.workShiftTypesList.forEach(element => {
                var workShiftTypeTextID = new OM.TextIdentifier();
                workShiftTypeTextID.identifier = element.identifier;
                workShiftTypeTextID.text = element.name;
                this.workShiftTypesSelectList.push(workShiftTypeTextID);
            });

            this.vanList = [];
            var filteredVans = xs[1].filter(x => xs[2].indexOf(x.vanId) > -1);
            filteredVans.forEach(element => {
                var textID = new OM.TextIdentifier();
                textID.identifier = element.vanId;
                textID.text = element.sigla;
                this.vanList.push(textID);
            });
        })
    }

    get isWorkingDay() {
        var workShiftType = this.workShiftTypesList.filter(x => x.identifier == this.newWorkShiftTypeId)[0];
        return workShiftType.score > 0;
    }

    get saveDisabled() {
        return !this.workShiftTypeId && !this.newVanIdentifier;
    }

    save() {
        this.callback(this.newWorkShiftTypeId, this.newVanIdentifier);
    }

}
